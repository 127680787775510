import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './views/admin/admin.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ViewProductsComponent } from './views/admin/view-products/view-products.component';
import { AdminModule } from './views/admin/admin.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TagModule } from 'primeng/tag';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownModule } from 'primeng/dropdown';
import { EditProductsComponent } from './views/admin/edit-products/edit-products.component';
import { AddProductComponent } from './views/admin/add-product/add-product.component';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorDialogComponent } from './shared/error-dialog/error-dialog.component';
import { OtherDetailsComponent } from './views/admin/other-details/other-details.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    ViewProductsComponent,
    EditProductsComponent,
    AddProductComponent,
    ErrorDialogComponent,
    OtherDetailsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    TagModule,
    HttpClientModule,
    AdminModule,
    AppRoutingModule,
    InputTextModule,
    BrowserAnimationsModule,
    DropdownModule,
    DialogModule,
    MultiSelectModule
  ],
  providers: [MessageService,DialogService],
  bootstrap: [AppComponent]
})
export class AppModule { }
