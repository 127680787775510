<div class="relative p-4">
<form [formGroup]="productForm" class="w-3/5 space-y-6">
    <!-- Category Dropdown -->
    <div class="flex items-center space-x-4">
        <label for="category" class="block text-sm font-medium text-gray-700 w-1/3">Category</label>
        <div class="w-full">
            <p-dropdown id="category" formControlName="category" [options]="categories" placeholder="Select a category"
                class="w-full" (onChange)="onCategoryChange($event, fileInput)" formControlName="category">
            </p-dropdown>
            <div *ngIf="productForm.get('category')?.invalid && productForm.get('category')?.touched"
                class="text-red-500 text-sm">
                Category is required
            </div>
        </div>
    </div>

    <!-- File Uploader -->
    <div class="flex items-center space-x-4 mb-4">
        <label for="cheatsheet" class="block text-sm font-medium text-gray-700 w-1/3">Product Recommendation Rules</label>
        <div class="flex items-center w-full">
          <!-- Upload Button -->
          <button type="button" 
                  class="px-4 py-2 w-28 bg-light-gray text-white text-sm font-medium rounded-md shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
                  (click)="fileInput.click()">
            {{uploadLabel}}
          </button>
          
          <!-- Hidden File Input -->
          <input #fileInput id="file" type="file" (change)="onFileChange($event, 'file')" 
                 class="hidden">
      
          <!-- File Name Display -->
          <span *ngIf="selectedFileName" class="ml-4 text-sm text-gray-700 truncate">
            {{ selectedFileName }}
          </span>
        </div>
        
        <!-- Validation Message -->
        <div *ngIf="productForm.get('cheatsheet')?.invalid && productForm.get('cheatsheet')?.touched" class="text-red-500 text-sm mt-2">
          File upload is required
        </div>
      </div>
      
</form>
<h2 class="mt-2 ml-60" [ngClass]="isApiFailed?'text-red':'text-success-green'">{{uploadResult}}</h2>
</div>