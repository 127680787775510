import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-other-details',
  templateUrl: './other-details.component.html',
  styleUrls: ['./other-details.component.css']
})
export class OtherDetailsComponent {
  productForm: FormGroup;
  selectedFileName: string | null = null;
  uploadResult: string;
  isApiFailed: boolean = false;
  uploadLabel: string = "Upload";
  categories = [
    { label: 'Life', value: 'life' },
    { label: 'Health', value: 'health' }
  ];

  constructor(private fb: FormBuilder, private apiService: ApiService) {
    this.productForm = this.fb.group({
      category: [''],
      file: [null],
    });
  }

  onCategoryChange(event: any, fileInput: HTMLInputElement) {
    fileInput.value = '';
    this.selectedFileName = '';
    this.uploadResult = '';
    this.uploadLabel = "Upload";
  }

  onFileChange(event: any, controlName: string) {
    const file = event.target.files[0];
    let formData = new FormData();
    if (file) {
      this.selectedFileName = file.name; // Store the file name
      this.productForm.patchValue({
        [controlName]: file,
      });
      this.productForm.get(controlName)?.markAsTouched();
      this.uploadLabel = "Replace";
      formData.append('file', file);
      this.apiService.addProductRecommendationFile(this.productForm.controls['category'].value, formData).subscribe({
        next: (res) => {
          this.uploadResult = res.message;
          this.isApiFailed = false;
        },
        error: (err) => {
          this.uploadResult = err.error.detail;
          this.isApiFailed = true;
        }
      })
    }
  }
}
