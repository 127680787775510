import { Injectable } from '@angular/core';
import { API } from '../view-models/Constants';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  public BaseURL = API.prodBaseURL;
  public apiKey = "msnd19829ASDn8786SS"

  constructor(public http: HttpClient) { }

  httpGetHelp(apiURL: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`
    });
    return this.http.get<any>(this.BaseURL+apiURL, { headers });
  }

  httpGetFileHelp(apiURL: string): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`
    });
    return this.http.get(this.BaseURL+apiURL, { observe:'response', responseType: 'blob', headers  });
  }

  httpPostHelp(apiURL: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`
    });
    return this.http.post<any>(this.BaseURL+apiURL, data,{ headers });
  }

  httpPatchHelp(apiURL: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`
    });
    return this.http.patch<any>(this.BaseURL+apiURL, data,{ headers });
  }

  httpDeleteHelp(apiURL: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`
    });
    return this.http.delete<any>(this.BaseURL+apiURL,{ headers });
  }

  getLifeProducts(category: string){ // Gets the grid data

    let api = "/products/"+category+"/";
    return this.httpGetHelp(api);
  }

  getFilesForProduct(product: string, category: string){
    let api = "/products/"+category+"/"+product;
    return this.httpGetHelp(api);
  }

  addProduct(category: string, company: string, product:string, formData: FormData){
    let api = "/upload/"+category+"/"+company+"/"+product;
    return this.httpPostHelp(api, formData);
  }

  getFile(category: string, company: string, product:string, fileName: string){
    let api = "/products/"+category+"/"+company+"/"+product+"/"+fileName;
    return this.httpGetFileHelp(api);
  }

  updateProduct(category: string, company: string, product:string, payload: any){
    let api = "/products/"+category+"/"+company+"/"+product;
    return this.httpPatchHelp(api,payload);
  }

  deleteFile(category: string, company: string, product:string, fileName: string){
    let api = "/products/"+category+"/"+company+"/"+product+"/"+fileName;
    return this.httpDeleteHelp(api);
  }

  addProductRecommendationFile(category: string, formData: FormData){
    let api = "/upload/benefit_rules/"+category
    return this.httpPostHelp(api, formData);
  }
}
