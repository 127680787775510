import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/services/api.service';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent {
  productForm?: FormGroup;
  categories!: SelectItem[];
  companies!: SelectItem[];
  lifeInsuranceCompanies = [
    { label: "Acko Life", value: "Acko Life" },
    { label: "Aditya Birla Sun Life", value: "Aditya Birla Sun Life" },
    { label: "Aegas Federal Life", value: "Aegas Federal Life" },
    { label: "Aviva Life", value: "Aviva Life" },
    { label: "Bajaj Allianz Life", value: "Bajaj Allianz Life" },
    { label: "Bandhan Life", value: "Bandhan Life" },
    { label: "Bharti AXA Life", value: "Bharti AXA Life" },
    { label: "Canara HSBC Life", value: "Canara HSBC Life" },
    { label: "Credit Access Life", value: "Credit Access Life" },
    { label: "Edelweiss Tokio Life", value: "Edelweiss Tokio Life" },
    { label: "Future Generali Life", value: "Future Generali Life" },
    { label: "Go Digit Life", value: "Go Digit Life" },
    { label: "HDFC Life", value: "HDFC Life" },
    { label: "ICICI Prudential", value: "ICICI Prudential" },
    { label: "IndiaFirst Life", value: "IndiaFirst Life" },
    { label: "Kotak Mahindra Life", value: "Kotak Mahindra Life" },
    { label: "LIC", value: "LIC" },
    { label: "Max Life", value: "Max Life" },
    { label: "PNB MetLife", value: "PNB MetLife" },
    { label: "Pramerica Life", value: "Pramerica Life" },
    { label: "Reliance Nippon Life", value: "Reliance Nippon Life" },
    { label: "Sahara India Life", value: "Sahara India Life" },
    { label: "SBI Life", value: "SBI Life" },
    { label: "Shriram Life", value: "Shriram Life" },
    { label: "Star Union Dai-Ichi Life", value: "Star Union Dai-Ichi Life" },
    { label: "Tata AIA", value: "Tata AIA" }
  ];
  healthInsuranceCompanies = [
    { label: "Acko General", value: "Acko General" },
    { label: "Aditya Birla Health", value: "Aditya Birla Health" },
    { label: "Bajaj Allianz General", value: "Bajaj Allianz General" },
    { label: "Cholamandalam MS", value: "Cholamandalam MS" },
    { label: "Future Generali India", value: "Future Generali India" },
    { label: "Go Digit General", value: "Go Digit General" },
    { label: "HDFC Ergo", value: "HDFC Ergo" },
    { label: "ICICI Lombard", value: "ICICI Lombard" },
    { label: "IFFCO Tokio", value: "IFFCO Tokio" },
    { label: "Kotak Mahindra General", value: "Kotak Mahindra General" },
    { label: "Kshema General", value: "Kshema General" },
    { label: "Liberty General", value: "Liberty General" },
    { label: "Magma HDI", value: "Magma HDI" },
    { label: "National Insurance Company", value: "National Insurance Company" },
    { label: "Navi General", value: "Navi General" },
    { label: "Raheja QBE General", value: "Raheja QBE General" },
    { label: "Reliance General", value: "Reliance General" },
    { label: "Royal Sundaram", value: "Royal Sundaram" },
    { label: "SBI General", value: "SBI General" },
    { label: "Shriram General", value: "Shriram General" },
    { label: "Tata AIG", value: "Tata AIG" },
    { label: "The New India Assurance", value: "The New India Assurance" },
    { label: "The Oriental Insurance", value: "The Oriental Insurance" },
    { label: "United India Insurance", value: "United India Insurance" },
    { label: "Universal Sompe General", value: "Universal Sompe General" },
    { label: "Zuno General", value: "Zuno General" }
  ];  
  filesUploaded: number = 0;
  productName: string;

  constructor(private fb: FormBuilder, private apiService: ApiService,
    private messageService: MessageService, private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.productForm = this.fb.group({
      category: [''],
      company: [''],
      productName: [''],
      productFeatures: [null, Validators.required],
      productTables: [null],
      cheatsheet: [null],
      salesPointers: [null, Validators.required]
    });
    this.categories = [
      { label: 'Life', value: 'life' },
      { label: 'Health', value: 'health' }
    ];

    this.companies = [];
  }

  visible: boolean = false;

  showDialog() {
    this.visible = true;
  }

  onSubmit() {
    let formData = new FormData();
    if (this.productForm?.valid) {
      for(let i=0; i<4; i++){
        switch(i){
          case 0:
            if(this.productForm.controls['productFeatures'].value!=null){
              this.filesUploaded++;
              let file: File = this.productForm.controls['productFeatures'].value;
              const renamedFile = new File([file], this.productForm.controls['productName'].value+'-'+"features."+file.name.split('.')[1], { type: file.type });
              formData.append('files',renamedFile);
            }
            break;
          case 1:
            if(this.productForm.controls['productTables'].value!=null){
              this.filesUploaded++;
              let file: File = this.productForm.controls['productTables'].value;
              const renamedFile = new File([file], this.productForm.controls['productName'].value+'-'+"tables."+file.name.split('.')[1], { type: file.type });
              formData.append('files',renamedFile);
            }
            break;
          case 2:
            if(this.productForm.controls['cheatsheet'].value!=null){
              this.filesUploaded++;
              let file: File = this.productForm.controls['cheatsheet'].value;
              const renamedFile = new File([file], this.productForm.controls['productName'].value+'-'+"cheatsheet."+file.name.split('.')[1], { type: file.type });
              formData.append('files',renamedFile);
            }
            break;
          case 3:
            if(this.productForm.controls['salesPointers'].value!=null){
              this.filesUploaded++;
              let file: File = this.productForm.controls['salesPointers'].value;
              const renamedFile = new File([file], this.productForm.controls['productName'].value+'-'+"salespointers."+file.name.split('.')[1], { type: file.type });
              formData.append('files',renamedFile);
            }
            break;
        }
      }
      this.apiService.addProduct(this.productForm.controls['category'].value,this.productForm.controls['company'].value,this.productForm.controls['productName'].value,formData).subscribe({
        next: (res) => {
          this.productName = this.productForm.controls['productName'].value;
          this.showDialog();
          this.productForm.reset();
        },
        error: (err) => {
          this.showErrorDialog(err);
        }
      });
    } else {
      this.markFormGroupTouched(this.productForm);
    }
  }

  resetForm(){
    this.productName = this.productForm.controls['productName'].value;
    this.productForm.reset();
  }

  showErrorDialog(error: any) {
    this.messageService.add({severity: 'error', summary: 'Error', detail: error.message || 'An error occurred while adding products.'});
    this.dialogService.open(ErrorDialogComponent, {
      header: 'API Error',
      width: '50%'
    });
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }


  onFileChange(event: any, field: string) {
    const file = event.target.files[0];
    this.productForm?.get(field)?.setValue(file);
  }

  onCategoryChange(event){
    if(this.productForm.get('category').value === 'life'){
      this.companies = [];
      this.companies = [...this.lifeInsuranceCompanies];
    }
    else{
      this.companies = [];
      this.companies = [...this.healthInsuranceCompanies];
    }
  }
}
