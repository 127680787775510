<header class="bg-green text-gray-800">
	<div class="flex justify-end">
		<ul class="flex navigation-links">
			<li [ngClass]="{'highlight':currentMenu==='View'}" (click)="setCurrentMenu('View')" class="flex hover:cursor-pointer">
				<a rel="noopener noreferrer" class="flex items-center">View</a>
			</li>
			<li [ngClass]="{'highlight':currentMenu==='add'}" (click)="setCurrentMenu('add')" class="flex hover:cursor-pointer">
				<a rel="noopener noreferrer" class="flex items-center">Add a product</a>
			</li>
			<li [ngClass]="{'highlight':currentMenu==='edit'}" (click)="setCurrentMenu('edit')" class="flex hover:cursor-pointer">
				<a rel="noopener noreferrer" class="flex items-center">Edit Details</a>
			</li>
			<li [ngClass]="{'highlight':currentMenu==='other'}" (click)="setCurrentMenu('other')" class="flex hover:cursor-pointer">
				<a rel="noopener noreferrer" class="flex items-center">Other Details</a>
			</li>
		</ul>
	</div>
</header>
<router-outlet></router-outlet>