<section>
  <span class="sub-header">Product List</span>
  <br/>
  <div class="flex gap-4">
      <div class="relative">
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <i class="pi pi-search text-dark-gray"></i>
          </span>
          <input type="text" [(ngModel)]="searchValue" (ngModelChange)="filterBySearch()" class="pl-10 pr-4 py-2 border border-light-gray rounded-md focus:outline-none focus:border-blue-500 search-field" placeholder="Search a product">
      </div>
      <div class="w-1/2">
        <div class="flex gap-4 items-center multi-select">
          <p-multiSelect [options]="categories" [(ngModel)]="selectedCategories" (onChange)="onCategoriesChange($event.value)" optionLabel="label" placeholder="Filter by category"></p-multiSelect>
          <p-multiSelect [options]="companies" [(ngModel)]="selectedCompanies" (onChange)="onCompaniesChange($event.value)" optionLabel="label" placeholder="Filter by company"></p-multiSelect>
      </div>  
      </div>
  </div>
</section>
<div class="card">
  <p-table
    styleClass="p-datatable-sm"
    [value]="tempProducts"
    dataKey="id"
    editMode="row"
    [paginator]="true"
    [rows]="10"
    class=" border-gray-300"
    [tableStyle]="{ 'min-width': '50rem' }"
  >
    <ng-template pTemplate="header">
      <tr class="border-b border-gray-300">
        <th style="width: 5%">SI.No.</th>
        <th style="width: 20%">Category</th>
        <th style="width: 20%">Company</th>
        <th style="width: 20%">Product Name</th>
        <th style="width: 5%">Features</th>
        <th style="width: 5%">Tables</th>
        <th style="width: 5%">Cheatsheet</th>
        <th style="width: 9%">Sales Pointers</th>
        <th style="width: 20%"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="product" class="border-b border-gray-300">
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              {{ product.id }}
            </ng-template>
            <ng-template pTemplate="output">
              {{ product.id }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown
                [options]="categories"
                appendTo="body"
                [(ngModel)]="product.category"
                [style]="{ 'width': '100%' }"
              ></p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              {{product.category}} 
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown
                [options]="companies"
                appendTo="body"
                [(ngModel)]="product.company"
                [style]="{ 'width': '100%' }"
              ></p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              {{product.company}} 
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="product.productName" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ product.productName }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <i (click)="visible=true; selectedColumnForFileUpdate='features'" *ngIf="product.features!=null"
                class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
                <i (click)="visible=true; selectedColumnForFileUpdate='features'" *ngIf="product.features===null"
                class="pi pi-upload text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
            <ng-template pTemplate="output">
              <i *ngIf="product.features!=null" (click)="previewFile(product, product.features)" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <i (click)="visible=true; selectedColumnForFileUpdate='tables'" *ngIf="product.tables!=null" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
              <i (click)="visible=true; selectedColumnForFileUpdate='tables'" *ngIf="product.tables===null" class="pi pi-upload text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
            <ng-template pTemplate="output">
              <i *ngIf="product.tables!=null" (click)="previewFile(product, product.tables)" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <i (click)="visible=true; selectedColumnForFileUpdate='cheatsheet'" *ngIf="product.cheatsheet!=null" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
              <i (click)="visible=true; selectedColumnForFileUpdate='cheatsheet'" *ngIf="product.cheatsheet===null" class="pi pi-upload text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
            <ng-template pTemplate="output">
              <i *ngIf="product.cheatsheet!=null" (click)="previewFile(product, product.cheatsheet)" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <i (click)="visible=true; selectedColumnForFileUpdate='salesPointers'" *ngIf="product.salesPointers!=null" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
              <i (click)="visible=true; selectedColumnForFileUpdate='salesPointers'" *ngIf="product.salesPointers===null" class="pi pi-upload text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
            <ng-template pTemplate="output">
              <i *ngIf="product.salesPointers!=null" (click)="previewFile(product, product.salesPointers)" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <div class="flex align-items-center justify-content-center gap-2">
            <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
              (click)="onRowEditInit(product)" class="p-button-rounded p-button-text"></button>
            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
              (click)="onRowEditSave(product)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
              (click)="onRowEditCancel(product, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-dialog class="mx-auto" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
    <div class="flex flex-col items-center">
      <span class="text-center mb-4">Do you want to delete this document or upload a new document?</span>
      <div class="flex justify-between">
        <button (click)="visible=false; deleteDocument()" type="button"
          class="px-8 mr-4 py-3 font-semibold rounded bg-light-gray">Delete</button>
        <input id="cheatsheet" type="file" (change)="onFileChange($event, 'cheatsheet')"
          class=" hidden mt-1 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
        <label class="px-8 hover:cursor-pointer py-3 font-semibold rounded bg-light-gray" for="cheatsheet">Upload</label>
      </div>
    </div>
  </p-dialog>
  <p-dialog class="mx-auto" [modal]="true" [(visible)]="showDeleteSuccessDialog || showFileChangeDialog" [style]="{ width: '25rem' }">
    <div class="flex flex-col items-center">
        <div class="mb-4">
            <i class="pi pi-check-circle text-success-green text-5xl"></i>
        </div>
        <span *ngIf="showDeleteSuccessDialog" class="text-center mb-4">Document is deleted successfully</span>
        <span *ngIf="showFileChangeDialog" class="text-center mb-4">Document is changed successfully</span>
        <button (click)="showDeleteSuccessDialog=false;showFileChangeDialog=false" type="button" class="px-8 py-3 font-semibold rounded bg-light-gray">OK</button>
    </div>
</p-dialog>
<p-dialog class="mx-auto" [modal]="true" [(visible)]="showEditsSuccessDialog" [style]="{ width: '25rem' }">
  <div class="flex flex-col items-center">
      <div class="mb-4">
          <i class="pi pi-check-circle text-success-green text-5xl"></i>
      </div>
      <span class="text-center mb-4">Edits are successful</span>
      <button (click)="showEditsSuccessDialog=false;fetchProducts()" type="button" class="px-8 py-3 font-semibold rounded bg-light-gray">OK</button>
  </div>
</p-dialog>
  <p-dialog header="Document Preview" [(visible)]="displayDialog" [modal]="true" [style]="{width: '60vw'}">
    <iframe *ngIf="fileUrl" [src]="fileUrl" width="100%" height="600px"></iframe>
  </p-dialog>
</div>
