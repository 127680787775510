import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/services/api.service';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { Product } from 'src/app/view-models/Products';

@Component({
  selector: 'app-view-products',
  templateUrl: './view-products.component.html',
  styleUrls: ['./view-products.component.css']
})
export class ViewProductsComponent {

  products: Product[] = [];
  tempProducts: Product[];
  categories!: SelectItem[];
  companies!: SelectItem[];
  clonedProducts: { [s: string]: Product } = {};
  searchValue: string = '';
  selectedCategories: any[] = [];
  selectedCompanies: any[] = [];
  lifeProducts: any = [];
  healthProducts: any = [];
  fileUrl: any;
  displayDialog: boolean = false;

  allCompanies = [
    { label: "LIC", value: "LIC" },
    { label: "Max Life", value: "Max Life" },
    { label: "HDFC Life", value: "HDFC Life" },
    { label: "ICICI Prudential", value: "ICICI Prudential" },
    { label: "Kotak Mahindra Life", value: "Kotak Mahindra Life" },
    { label: "Aditya Birla Sun Life", value: "Aditya Birla Sun Life" },
    { label: "Tata AIA", value: "Tata AIA" },
    { label: "SBI Life", value: "SBI Life" },
    { label: "Bajaj Allianz Life", value: "Bajaj Allianz Life" },
    { label: "PNB MetLife", value: "PNB MetLife" },
    { label: "Reliance Nippon Life", value: "Reliance Nippon Life" },
    { label: "Aviva Life", value: "Aviva Life" },
    { label: "Sahara India Life", value: "Sahara India Life" },
    { label: "Shriram Life", value: "Shriram Life" },
    { label: "Bharti AXA Life", value: "Bharti AXA Life" },
    { label: "Future Generali Life", value: "Future Generali Life" },
    { label: "Aegas Federal Life", value: "Aegas Federal Life" },
    { label: "Canara HSBC Life", value: "Canara HSBC Life" },
    { label: "Bandhan Life", value: "Bandhan Life" },
    { label: "Pramerica Life", value: "Pramerica Life" },
    { label: "Star Union Dai-Ichi Life", value: "Star Union Dai-Ichi Life" },
    { label: "IndiaFirst Life", value: "IndiaFirst Life" },
    { label: "Edelweiss Tokio Life", value: "Edelweiss Tokio Life" },
    { label: "Credit Access Life", value: "Credit Access Life" },
    { label: "Acko Life", value: "Acko Life" },
    { label: "Go Digit Life", value: "Go Digit Life" },
    { label: "Acko General", value: "Acko General" },
    { label: "Bajaj Allianz General", value: "Bajaj Allianz General" },
    { label: "Cholamandalam MS", value: "Cholamandalam MS" },
    { label: "Future Generali India", value: "Future Generali India" },
    { label: "Go Digit General", value: "Go Digit General" },
    { label: "HDFC Ergo", value: "HDFC Ergo" },
    { label: "ICICI Lombard", value: "ICICI Lombard" },
    { label: "IFFCO Tokio", value: "IFFCO Tokio" },
    { label: "Kotak Mahindra General", value: "Kotak Mahindra General" },
    { label: "Kshema General", value: "Kshema General" },
    { label: "Liberty General", value: "Liberty General" },
    { label: "Magma HDI", value: "Magma HDI" },
    { label: "National Insurance Company", value: "National Insurance Company" },
    { label: "Navi General", value: "Navi General" },
    { label: "Raheja QBE General", value: "Raheja QBE General" },
    { label: "Reliance General", value: "Reliance General" },
    { label: "Royal Sundaram", value: "Royal Sundaram" },
    { label: "SBI General", value: "SBI General" },
    { label: "Shriram General", value: "Shriram General" },
    { label: "Tata AIG", value: "Tata AIG" },
    { label: "The New India Assurance", value: "The New India Assurance" },
    { label: "The Oriental Insurance", value: "The Oriental Insurance" },
    { label: "United India Insurance", value: "United India Insurance" },
    { label: "Universal Sompe General", value: "Universal Sompe General" },
    { label: "Zuno General", value: "Zuno General" },
    { label: "Aditya Birla Health", value: "Aditya Birla Health" },
    { label: "Care Health", value: "Care Health" },
    { label: "Galaxy Health", value: "Galaxy Health" },
    { label: "Narayana Health", value: "Narayana Health" },
    { label: "Manipal Cigna", value: "Manipal Cigna" },
    { label: "Niva Bupa", value: "Niva Bupa" },
    { label: "Star Health", value: "Star Health" }
  ];
  lifeInsuranceCompanies = [
    { label: "Acko Life", value: "Acko Life" },
    { label: "Aditya Birla Sun Life", value: "Aditya Birla Sun Life" },
    { label: "Aegas Federal Life", value: "Aegas Federal Life" },
    { label: "Aviva Life", value: "Aviva Life" },
    { label: "Bajaj Allianz Life", value: "Bajaj Allianz Life" },
    { label: "Bandhan Life", value: "Bandhan Life" },
    { label: "Bharti AXA Life", value: "Bharti AXA Life" },
    { label: "Canara HSBC Life", value: "Canara HSBC Life" },
    { label: "Credit Access Life", value: "Credit Access Life" },
    { label: "Edelweiss Tokio Life", value: "Edelweiss Tokio Life" },
    { label: "Future Generali Life", value: "Future Generali Life" },
    { label: "Go Digit Life", value: "Go Digit Life" },
    { label: "HDFC Life", value: "HDFC Life" },
    { label: "ICICI Prudential", value: "ICICI Prudential" },
    { label: "IndiaFirst Life", value: "IndiaFirst Life" },
    { label: "Kotak Mahindra Life", value: "Kotak Mahindra Life" },
    { label: "LIC", value: "LIC" },
    { label: "Max Life", value: "Max Life" },
    { label: "PNB MetLife", value: "PNB MetLife" },
    { label: "Pramerica Life", value: "Pramerica Life" },
    { label: "Reliance Nippon Life", value: "Reliance Nippon Life" },
    { label: "Sahara India Life", value: "Sahara India Life" },
    { label: "SBI Life", value: "SBI Life" },
    { label: "Shriram Life", value: "Shriram Life" },
    { label: "Star Union Dai-Ichi Life", value: "Star Union Dai-Ichi Life" },
    { label: "Tata AIA", value: "Tata AIA" }
  ];
  healthInsuranceCompanies = [
    { label: "Acko General", value: "Acko General" },
    { label: "Aditya Birla Health", value: "Aditya Birla Health" },
    { label: "Bajaj Allianz General", value: "Bajaj Allianz General" },
    { label: "Cholamandalam MS", value: "Cholamandalam MS" },
    { label: "Future Generali India", value: "Future Generali India" },
    { label: "Go Digit General", value: "Go Digit General" },
    { label: "HDFC Ergo", value: "HDFC Ergo" },
    { label: "ICICI Lombard", value: "ICICI Lombard" },
    { label: "IFFCO Tokio", value: "IFFCO Tokio" },
    { label: "Kotak Mahindra General", value: "Kotak Mahindra General" },
    { label: "Kshema General", value: "Kshema General" },
    { label: "Liberty General", value: "Liberty General" },
    { label: "Magma HDI", value: "Magma HDI" },
    { label: "National Insurance Company", value: "National Insurance Company" },
    { label: "Navi General", value: "Navi General" },
    { label: "Raheja QBE General", value: "Raheja QBE General" },
    { label: "Reliance General", value: "Reliance General" },
    { label: "Royal Sundaram", value: "Royal Sundaram" },
    { label: "SBI General", value: "SBI General" },
    { label: "Shriram General", value: "Shriram General" },
    { label: "Tata AIG", value: "Tata AIG" },
    { label: "The New India Assurance", value: "The New India Assurance" },
    { label: "The Oriental Insurance", value: "The Oriental Insurance" },
    { label: "United India Insurance", value: "United India Insurance" },
    { label: "Universal Sompe General", value: "Universal Sompe General" },
    { label: "Zuno General", value: "Zuno General" }
  ];  

  constructor(private apiService: ApiService, private sanitizer: DomSanitizer,
    private messageService: MessageService,private dialogService: DialogService) {
  }

  downloadFile(blob: Blob, filename: string): void {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href); // Clean up URL
  }

  ngOnInit() {
    this.fetchProducts();

    this.categories = [
      { label: 'Life', value: 'Life' },
      { label: 'Health', value: 'Health' }
    ];

    this.companies = [...this.allCompanies]
  }

  fetchProducts() {
    this.apiService.getLifeProducts('life').subscribe({
      next: (res) => {
        this.lifeProducts = res;
        this.apiService.getLifeProducts('health').subscribe({
          next: (res) => {
            this.healthProducts = res;
            this.processProducts();
          },
          error: (err) => {
            this.showErrorDialog(err);
          }
        });
      },
      error: (err) => {
        this.showErrorDialog(err);
      }
    });
  }

  processProducts() {
    for (let i = 0; i < this.lifeProducts.length; i++) {
      let tempObject: Product = {} as Product;
      tempObject.id = (i + 1).toString();
      tempObject.productName = this.lifeProducts[i].product;
      tempObject.company = this.lifeProducts[i].company;
      tempObject.category = "Life";
      tempObject.cheatsheet = this.getFileName("cheatsheet", this.lifeProducts[i].files);
      tempObject.features = this.getFileName("features", this.lifeProducts[i].files);
      tempObject.tables = this.getFileName("tables", this.lifeProducts[i].files);
      tempObject.salesPointers = this.getFileName("salespointers", this.lifeProducts[i].files);
      this.products.push(tempObject);
    }

    for (let j = 0; j < this.healthProducts.length; j++) {
      let tempObject: Product = {} as Product;
      tempObject.id = (this.lifeProducts.length+1 + j).toString();
      tempObject.productName = this.healthProducts[j].product;
      tempObject.company = this.healthProducts[j].company;
      tempObject.category = "Health";
      tempObject.cheatsheet = this.getFileName("cheatsheet", this.healthProducts[j].files);
      tempObject.features = this.getFileName("features", this.healthProducts[j].files);
      tempObject.tables = this.getFileName("tables", this.healthProducts[j].files);
      tempObject.salesPointers = this.getFileName("salespointers", this.healthProducts[j].files);
      this.products.push(tempObject);
    }

    this.tempProducts = this.products;
  }

  showErrorDialog(error: any) {
    this.messageService.add({severity: 'error', summary: 'Error', detail: error.message || 'An error occurred while fetching products.'});
    this.dialogService.open(ErrorDialogComponent, {
      header: 'API Error',
      width: '50%'
    });
  }

  onRowEditInit(product: Product) {
    this.clonedProducts[product.id as string] = { ...product };
  }

  onRowEditSave(product: Product) {

  }

  onRowEditCancel(product: Product, index: number) {
    this.products[index] = this.clonedProducts[product.id as string];
    delete this.clonedProducts[product.id as string];
  }

  filterBySearch() {
    this.tempProducts = this.products.filter(p => p.productName.toLowerCase().includes(this.searchValue.toLowerCase()));
  }

  onCategoriesChange(event: any) {
    if (this.selectedCategories.length === 0) {
      this.tempProducts = [...this.products]; // If no categories selected, show all products
    } else {
      console.log(this.selectedCategories);
      
      let selectedCategory: any[] = [];
      this.selectedCategories.forEach(element => {
        selectedCategory.push(element.label);
      });
      if (selectedCategory.length>1)
      {
        this.companies = [];
        this.companies = [...this.allCompanies];
      }
      else if(selectedCategory[0] === 'Life'){
        this.companies = [];
        this.companies = [...this.lifeInsuranceCompanies];
      }
      else{
        this.companies = [];
        this.companies = [...this.healthInsuranceCompanies];
      }
      this.tempProducts = this.products.filter(product =>
        selectedCategory.includes(product.category)
      );
    }
  }

  onCompaniesChange(event: any) {
    if (this.selectedCompanies.length === 0) {
      this.tempProducts = [...this.products]; // If no categories selected, show all products
    } else {
      let selectedCompany: any[] = [];
      this.selectedCompanies.forEach(element => {
        selectedCompany.push(element.label);
      });
      this.tempProducts = this.products.filter(product =>
        selectedCompany.includes(product.company)
      );
    }
  }

  getFileName(keyword: string, fileNames): string | undefined {
    const fileName = fileNames.find(file => file.includes(keyword));
    return fileName ? fileName : null;
  }

  previewFile(product: any, fileName: string) {
    this.apiService.getFile(product.category.toLowerCase(), product.company, product.productName, fileName).subscribe((response) => {
      const blob = response.body;
      const url = URL.createObjectURL(blob);
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.getFileType(fileName)?this.displayDialog = true: this.downloadFile(blob, fileName);
    });
  }

  getFileType(fileName: string): boolean {
    const extension = fileName.split('.').pop()?.toLowerCase();
    
    switch (extension) {
      case 'pdf':
        return true;
      case 'txt':
        return true;
      default:
        return false;
    }
  }
}
